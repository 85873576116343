@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollbar-hide::-webkit-scrollbar {
  width: 7px;
}

.scrollbar-hide::-webkit-scrollbar-track {
  background: none;
}

.scrollbar-hide::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
  border-radius: 20px;
}
@layer base {
  /* main {
    @apply font-poppins;
  } */
}

@layer components {
  .header {
    @apply fixed min-w-full bg-slate-50 shadow-md h-[3.5rem] flex items-center justify-between px-6;
  }
  .sidebar {
    @apply relative flex flex-col;
  }
  .sidebar-container {
    @apply list-none flex flex-col justify-center min-h-full overflow-y-scroll mb-16 pl-6;
  }
  .nav-item {
    @apply cursor-pointer font-medium text-base uppercase tracking-wide  py-1 pl-2 border-b-0 hover:text-gray-300
    transition-all ease-in-out duration-200  rounded-sm;
  }
  .sub-item {
    @apply transition-all ease-in-out duration-200 py-1 pl-2 ml-4 border-b border-gray-400 hover:text-gray-900;
  }

  label {
    @apply text-gray-700;
  }
  .input-text {
    @apply bg-transparent border border-gray-400 rounded py-[6px] px-2 focus:outline-none focus:border-sky-300 focus:ring-1 focus:ring-sky-500;
  }
  .btn-i {
    @apply text-xs flex flex-row md:flex-col justify-center items-center border my-2 py-1 px-2 md:px-5 shadow-sm active:shadow-none rounded-md  font-semibold text-center text-gray-950 hover:text-gray-500 active:text-gray-950 hover:bg-sky-200 bg-sky-300 active:bg-sky-300;
  }

  .btn {
    @apply border px-8 py-1 border-[#1e81b0] rounded-lg  text-slate-800 hover:text-white hover:bg-[#1e81b0];
  }
  .error {
    @apply text-red-600 text-sm font-semibold rounded-md z-10  -right-6;
  }

  .container-table {
    @apply px-5 py-8;
  }
  .table {
    @apply min-w-full text-sm text-left text-gray-500;
  }
  .thead {
    @apply text-xs text-gray-700 uppercase bg-gray-50 sticky top-0;
  }
  .tr-body {
    @apply bg-white border-b hover:bg-gray-100 overflow-y-auto;
  }
  .th {
    @apply py-3 px-6;
  }
  .td {
    @apply py-3 px-6;
  }
  .icon {
    @apply w-6 h-6;
  }
}
